import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './Layout/Layout';
import AcceptedOrders from './Screens/Acceptedorders/AcceptedOrders';
import CancelOrders from './Screens/CancelOrders/CancelOrders';
import Dashboard from './Screens/Dashboard/Dashboard';
import DeliveredOrder from './Screens/DeliveredOrders/DeliveredOrders';
import DispatchedOrder from './Screens/DispatcheOrders/DispatchedOrders';
import HoldOrders from './Screens/HoldOrders/Holdorder';
import NewOrders from './Screens/Neworders/NewOrders';
import Orderdetails from './Screens/OrderDetails/Orderdetails';
import ReturnedOrders from './Screens/ReturnOrders/ReturnedOrders';
import Signin from './Screens/Signin/Signin';
import jwtDecode from "jwt-decode"
import Users from './Screens/Users/Users';
import SearchOrder from './Screens/Search/SearchOrder';
import Home from './Screens/Home/Home';
import Report from './Screens/Report/Report'
import Restricted from './Screens/Restricted';
import OrderReport from './Screens/OrderReport/OrderReport';

function App() {
  localStorage.removeItem("lastUrl")
  const GetUserInfo = () => {
    try {
      if (localStorage.token) {
        return jwtDecode(localStorage.token || "undefined")
      }
      return undefined
    } catch (err) {
      if (err.message === "Invalid token specified: undefined") {
        localStorage.removeItem("token")
        window.location.pathname = "/signin"
        return undefined
      }
    }
  }
  let UserInfo = GetUserInfo() || {}
  const Permissions = UserInfo.permissions ? UserInfo.permissions.split(",") : ["/new-orders"]


  const PermissionPages = Permissions.filter((x) => x.includes("/"))[0] ? Permissions.filter((x) => x.includes("/")) : ['/new-orders']

  console.log(PermissionPages)

  const Screens = {
    "/": Home,
    "/dashboard": Dashboard,
    "/new-order": NewOrders,
    "/accepted-order": AcceptedOrders,
    "/hold-order": HoldOrders,
    "/cancel-order": CancelOrders,
    "/dispatch-order": DispatchedOrder,
    "/delivered-order": DeliveredOrder,
    "/returned-order": ReturnedOrders,
    //------------------
    "/report": Report,
    "/order/:orderid": Orderdetails,
    "/users": Users,
    "/search": SearchOrder,
    "/order-report": OrderReport
  }

  const PagesName = {
    "/dashboard": "Dashboard",
    "/new-order": "New Orders",
    "/accepted-order": "Accepted Orders",
    "/cancel-order": "Cancelled Orders",
    "/dispatch-order": 'Dispatched Orders',
    "/delivered-order": "Delivered Orders",
    "/returned-order": "Returned Orders",
    "/order/:orderid": "Order Details",
    "/hold-order": "Hold Orders",
    "/users": "Manage Users",
    "/search": "Search Orders",
    'accept-order=>': "Accept Order Button",
    'dispatch-order=>': "Dispatch Order Button",
    "hold-order=>": 'Hold Order Button',
    "cancel-order=>": 'Cancel Order Button',
    "update-transit-status=>": "Update Transit Status Button",
    "add-user=>": "Add User Button",
    "remove-user=>": "Remove User Button",
    "update-user=>": "Update User Button",
    "/order-report": "Order Report",
    "/report": "Report and filter order"
  }



  const paths = {
    dashboard: "/dashboard",
    newOrders: "/new-order",
    acceptedOrders: "/accepted-order",
    holdOrders: "/hold-order",
    cancelOrders: "/cancel-order",
    dispatchedOrders: "/dispatch-order",
    deliveredOrders: "/delivered-order",
    returnedOrders: "/returned-order",
    orderdetails: "/order/:orderid",
    manegeUsers: "/users",
    searchorder: "/search",
    orderreport: "/order-report",
    //--------------------------------
    report: '/report'

  }


  const routes = (
    <Routes>
      {
        PermissionPages.map((pages, key) => {
          let Component = Screens[pages]
          if (pages === "/") {
            return <Route GetUserInfo={GetUserInfo} key={key} path='/' element={<Component />} />
          }
          return (<Route GetUserInfo={GetUserInfo} key={key} path={pages} element={<Component PagesName={PagesName} />} />)
        })
      }
    </Routes>
  )

  return (
    <BrowserRouter>
      <div className="App">
        {
          window.location.pathname === "/signin" ? (
            <Routes>
              <Route path="/signin" element={<Signin />} />
            </Routes>
          ) :
            window.location.pathname !== "/restricted" &&
              !localStorage.token ?
              window.location.pathname = "/signin"
              :
              (
                <Layout PagesName={PagesName} UserInfo={UserInfo} pages={Object.keys(Screens)} PermissionPages={PermissionPages} routes={routes} paths={paths} />
              )
        }
        {
          <Routes>
            <Route path="/restricted" element={<Restricted />} />
          </Routes>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;


